<template>
  <div>
    <el-dialog
        :title="title"
        :visible.sync="isOpenModal"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        width="600px"
        @closed="closeModal" @open="openModal">
      <div style="width: 80%">
        <ch-form
            :render-option="option"
            :model="modalForm"
            ref="modalForm"
            :rules="rules"
            :props="{ labelWidth: '100px', paddingRight: '0px'}"
        />
      </div>
      <span slot="footer" class="flex_con">
            <ch-button @click="closeModal" type="cancel">取消</ch-button>
            <ch-button
                @click="handleModal('modalForm')"
                :loading="isButtonLoading"
                type="ok" >确定</ch-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    getListFunction: {
      type: Function
    }
  },
  data(){
    return {
      title: '',
      modalForm: {},
      isOpenModal: false,
      isButtonLoading: false,
      rules: {
        reply: [{required: true, message: "请输入回复内容", trigger: ["blur", "change"],},],
      }
    }
  },
  computed: {
    option: function () {
        return [
          {
            type:'textarea',
            label: "回复内容:",
            prop: "reply",
            placeholder: "请输入回复内容",
            props: { maxlength: 200, showWordLimit: true, autosize: false }
          },
        ]
    },
  },
  methods: {
    // 打开前处理
    openModal() {
      this.$nextTick(() => {
        this.$refs?.modalForm?.clearValidate();
      })
    },
    openAdd(ids) {
      this.title = '回复内容'
      this.modalForm.evaluateIds = [...ids]
      this.isOpenModal = true
    },
    // 执行 增加 或 修改
    handleModal(formRef) {
      this.$refs[formRef].validate((valid) => {
        if (valid) {
          this.isButtonLoading = true
          this.$curl.post("/hm/order/evaluateReply", this.modalForm)
              .then(() => {
                this.$message.success("回复成功！");
                this.closeModal()
                this.getListFunction?.()
              }).catch(() => this.isButtonLoading = false)
        }
      });
    },
    // 关闭前清除数据
    closeModal() {
      this.modalForm = {}
      this.isOpenModal = false
      this.isButtonLoading = false
    },
  }
}
</script>